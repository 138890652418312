@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0, 0%, 0%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 202.8 78% 41%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 0 0% 100%;

    --light: 0 0% 100%;
    --light-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer components {
  .footer-curve {
    width: 100vw;
    height: 10vw;
    background: radial-gradient(
      circle 130vw at 50vw -120vw,
      transparent 260vw,
      black 0
    );
  }

  .spr-btn {
    @apply inline-flex items-center justify-center text-center rounded-full transition-colors disabled:pointer-events-none disabled:opacity-50 cursor-pointer;
  }

  .spr-btn-default {
    @apply uppercase text-mobile-sm/overline-2 px-6 py-1.5 gap-6 tablet-sm:text-tablet-sm/overline-3 tablet-sm:px-8 tablet-sm:py-2 desktop-lg:text-desktop-large/overline-3-bold desktop-lg:py-3;
  }
  .spr-btn-primary {
    @apply border border-solid border-transparent bg-primaryOceanBlue text-white fill-white hover:bg-prussianBlue active:bg-midnightBlue focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primarySkyBlue focus:outline-none focus:ring-2 focus:ring-primarySkyBlue;
  }

  .spr-btn-secondary {
    @apply border border-solid bg-white border-primaryOceanBlue text-primaryOceanBlue hover:text-prussianBlue hover:border-prussianBlue active:text-midnightBlue active:border-midnightBlue;
  }

  .spr-btn-primary-default {
    @apply spr-btn spr-btn-default spr-btn-primary;
  }
}

@layer utilities {
  .font-var-all-small-caps {
    font-variant: all-small-caps;
  }

  .sprinklr-gradient {
    background: linear-gradient(
      63deg,
      #fcb549 11.82%,
      #8ecd7b 57.29%,
      #34c8ed 70.39%
    );
  }

  .hero-image-gradient {
    background:
      linear-gradient(
        0deg,
        rgba(0, 186, 233, 0.3) 0%,
        rgba(0, 186, 233, 0.3) 100%
      ),
      lightgray 50% / cover no-repeat;
    mix-blend-mode: multiply, normal;
  }

  .fade-edges {
    mask-image: linear-gradient(
      var(--mask-direction, to right),
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 20%,
      hsl(0 0% 0% / 1) 80%,
      hsl(0 0% 0% / 0)
    );
  }

  .fade-edges-y {
    mask-image: linear-gradient(
      var(--mask-direction, to bottom),
      hsl(0 0% 0% / 1),
      hsl(0 0% 0% / 0) 95%
    );
  }

  .tabs-pointer-horizontal {
    clip-path: polygon(
      0 8px,
      calc(var(--tab-pointer-location-horizontal) * 100% - 8px) 8px,
      calc(var(--tab-pointer-location-horizontal) * 100%) 2px,
      calc(var(--tab-pointer-location-horizontal) * 100% + 8px) 8px,
      100% 8px,
      100% 100%,
      0% 100%
    );
  }

  .tabs-pointer-vertical {
    clip-path: polygon(
      68% 0,
      68% calc(var(--tab-pointer-location-vertical) * 100% - 10px),
      0% calc(var(--tab-pointer-location-vertical) * 100%),
      68% calc(var(--tab-pointer-location-vertical) * 100% + 10px),
      68% 100%,
      100% 100%,
      100% 0%
    );
  }

  .message-bubble {
    clip-path: polygon(
      0% 20px,
      2px 15px,
      5px 10px,
      10px 5px,
      15px 2px,
      20px 0%,
      calc(100% - 20px) 0%,
      calc(100% - 15px) 2px,
      calc(100% - 10px) 5px,
      calc(100% - 5px) 10px,
      calc(100% - 2px) 15px,
      100% 20px,
      100% calc(100% - 42px),
      calc(100% - 2px) calc(100% - 37px),
      calc(100% - 5px) calc(100% - 32px),
      calc(100% - 10px) calc(100% - 27px),
      calc(100% - 15px) calc(100% - 24px),
      calc(100% - 20px) calc(100% - 22px),
      57% calc(100% - 22px),
      50% 100%,
      48% calc(100% - 22px),
      calc(0% + 20px) calc(100% - 22px),
      calc(0% + 15px) calc(100% - 24px),
      calc(0% + 10px) calc(100% - 27px),
      calc(0% + 5px) calc(100% - 32px),
      calc(0% + 2px) calc(100% - 37px),
      0% calc(100% - 42px),
      0% 20px
    );
  }

  @media (min-width: 768px) {
    .message-bubble {
      clip-path: polygon(
        0% 20px,
        2px 15px,
        5px 10px,
        10px 5px,
        15px 2px,
        20px 0%,
        calc(100% - 20px) 0%,
        calc(100% - 15px) 2px,
        calc(100% - 10px) 5px,
        calc(100% - 5px) 10px,
        calc(100% - 2px) 15px,
        100% 20px,
        100% calc(100% - 50px),
        calc(100% - 2px) calc(100% - 45px),
        calc(100% - 5px) calc(100% - 40px),
        calc(100% - 10px) calc(100% - 35px),
        calc(100% - 15px) calc(100% - 32px),
        calc(100% - 20px) calc(100% - 30px),
        57% calc(100% - 30px),
        50% 100%,
        48% calc(100% - 30px),
        calc(0% + 20px) calc(100% - 30px),
        calc(0% + 15px) calc(100% - 32px),
        calc(0% + 10px) calc(100% - 35px),
        calc(0% + 5px) calc(100% - 40px),
        calc(0% + 2px) calc(100% - 45px),
        0% calc(100% - 50px),
        0% 20px
      );
    }
  }

  @media (min-width: 1280px) {
    .tabs-pointer-horizontal {
      clip-path: polygon(
        0 12px,
        calc(var(--tab-pointer-location-horizontal) * 100% - 12px) 12px,
        calc(var(--tab-pointer-location-horizontal) * 100%) 2px,
        calc(var(--tab-pointer-location-horizontal) * 100% + 12px) 12px,
        100% 12px,
        100% 100%,
        0% 100%
      );
    }

    .tabs-pointer-vertical {
      clip-path: polygon(
        70% 0,
        70% calc(var(--tab-pointer-location-vertical) * 100% - 20px),
        0% calc(var(--tab-pointer-location-vertical) * 100%),
        70% calc(var(--tab-pointer-location-vertical) * 100% + 20px),
        70% 100%,
        100% 100%,
        100% 0%
      );
    }
    .message-bubble {
      clip-path: polygon(
        0% 20px,
        2px 15px,
        5px 10px,
        10px 5px,
        15px 2px,
        20px 0%,
        calc(100% - 20px) 0%,
        calc(100% - 15px) 2px,
        calc(100% - 10px) 5px,
        calc(100% - 5px) 10px,
        calc(100% - 2px) 15px,
        100% 20px,
        100% calc(100% - 56px),
        calc(100% - 2px) calc(100% - 51px),
        calc(100% - 5px) calc(100% - 46px),
        calc(100% - 10px) calc(100% - 41px),
        calc(100% - 15px) calc(100% - 38px),
        calc(100% - 20px) calc(100% - 36px),
        57% calc(100% - 36px),
        50% 100%,
        48% calc(100% - 36px),
        calc(0% + 20px) calc(100% - 36px),
        calc(0% + 15px) calc(100% - 38px),
        calc(0% + 10px) calc(100% - 41px),
        calc(0% + 5px) calc(100% - 46px),
        calc(0% + 2px) calc(100% - 51px),
        0% calc(100% - 56px),
        0% 20px
      );
    }
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .header-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #d9d9d9 transparent;
  }
  .header-scrollbar::-webkit-scrollbar {
    width: 6px;
  }

  .header-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .header-scrollbar::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 9999px;
  }

  .header-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #077ab9;
  }

  .toc-scrollbar::-webkit-scrollbar {
    width: 4px;
  }

  .toc-scrollbar::-webkit-scrollbar-track {
    background: #dbdbdb;
  }

  .toc-scrollbar::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 9999px;
  }

  .toc-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #077ab9;
  }

  .filter-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #dbdbdb transparent;
  }
  .filter-scrollbar::-webkit-scrollbar {
    width: 4px;
  }

  .filter-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .filter-scrollbar::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 9999px;
  }

  .filter-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #077ab9;
  }

  .spr-gradient-tropical-breeze {
    position: relative;
    z-index: 0;
  }

  .spr-gradient-tropical-breeze::before {
    z-index: -1;
    content: '';
    position: absolute;
    padding: 1px;
    border-radius: 10px;
    inset: 0;
    background: linear-gradient(
      90.31deg,
      #faa21b -7.84%,
      #70bf54 19.12%,
      #00bae9 73.04%,
      #118acb 100%
    );
    mask:
      linear-gradient(#000 0 0) content-box,
      linear-gradient(#000 0 0);
    mask-composite: exclude;
  }
}

@keyframes scroll-wheel {
  0% {
    top: 0%;
    transform: translateY(0);
    height: 3px;
    opacity: 1;
  }

  10% {
    top: 0%;
    transform: translateY(0);
  }

  20% {
    opacity: 0.7;
    height: 16px;
  }

  40% {
    transform: translateY(-100%);
    top: 100%;
    height: 3px;
    opacity: 0;
  }

  70% {
    transform: translateY(0);
    top: 0%;
    height: 3px;
    opacity: 0;
  }
}

@keyframes reveal {
  0%,
  25% {
    r: var(--radius);
  }
  30% {
    r: var(--radius-min);
  }
  75%,
  100% {
    r: var(--radius-max);
  }
}

@keyframes blur-bg-move {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(150%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* BG Gradient animation Keyframes */

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/normal900/normal900.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/italic900/italic900.woff2') format('woff2');
  font-display: swap;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/normal800/normal800.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/italic800/italic800.woff2') format('woff2');
  font-display: swap;
  font-style: italic;
  font-weight: 800;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/normal700/normal700.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/italic700/italic700.woff2') format('woff2');
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/normal600/normal600.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/italic600/italic600.woff2') format('woff2');
  font-display: swap;
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/normal500/normal500.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/italic500/italic500.woff2') format('woff2');
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/normal400/normal400.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/italic400/italic400.woff2') format('woff2');
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/normal300/normal300.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/italic300/italic300.woff2') format('woff2');
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/normal100/normal100.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/fonts/proxima-nova/italic100/italic100.woff2') format('woff2');
  font-display: swap;
  font-style: italic;
  font-weight: 100;
  font-stretch: normal;
}
